:root {
  --bg-100: rgb(27, 27, 27);
  --bg-200: rgb(32, 32, 32);
  --bg-300: rgb(46, 46, 46);
  --bg-400: rgb(49, 49, 49);
  --text-color: white;
  --primary-color-50: rgb(165, 79, 119);
  --primary-color-100: rgb(184, 88, 133);
  --primary-color-200: rgb(194, 98, 143);
  --primary-color-300: rgb(204, 108, 153);
  --primary-color-400: rgb(131, 64, 93);
}
html,
body,
#root,
.App {
  color: var(--text-color);
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: var(--bg-200);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.05rem;
  line-height: 1.6rem;
}
body {
  height: fit-content;
}
.App {
  background-color: var(--bg-200);
  display: flex;
  flex-direction: column;
}
a {
  text-decoration: none;
  color: var(--primary-color-300);
  margin-top: 2px;
  padding: 0.7rem 0.3rem;
}
button:hover {
  cursor: pointer;
}

/*Utility classes*/
.container {
  width: 90%;
  max-width: 1000px;
  margin: auto;
  margin-bottom: 1rem;
}
.container--large {
  width: 90%;
  max-width: 1300px;
  margin: auto;
  margin-bottom: 1rem;
}
.split {
  display: flex;
  flex-direction: column;
}
.split > * {
  flex-basis: 100%;
  min-width: 0;
}
.split > * + * {
  margin-top: 1rem;
}
@media (min-width: 1024px) {
  .split > * + * {
    margin-left: 1rem;
    margin-top: 0;
  }
  .split {
    flex-direction: row;
  }
}
.primary-btn,
.secondary-btn {
  padding-left: 0;
  padding-right: 0;
  border: 2px solid var(--primary-color-100);
  border-radius: 6px;
  transition: all 100ms ease-in-out;
  text-align: center;
  display: block;
}
.primary-btn {
  background-color: var(--primary-color-100);
  color: white;
}
.primary-btn:hover {
  border: 2px solid var(--primary-color-50);
  background-color: var(--primary-color-50);
}
.secondary-btn:hover {
  background-color: var(--primary-color-100);
  color: white;
  transform: translateY(10px);
}
.primary-btn:hover,
.secondary-btn:hover {
  transform: translateY(2px);
}
.trans-btn {
  background-color: transparent;
  border: none;
  outline: none;
}
.primary-btn:disabled {
  background-color: var(--primary-color-400);
  border: 2px solid var(--primary-color-400);
}
.primary-btn:disabled:hover {
  cursor: not-allowed;
  transform: none;
}

/*Nav*/
nav {
  display: flex;
  justify-content: center;
  box-shadow: 0;
  box-shadow: 0px 4px 4px -4px black;
  background-color: var(--bg-100);
}
@media (min-width: 768px) {
  nav {
    justify-content: end;
  }
}
nav > * {
  margin: 0 0.7rem;
}
nav .active {
  border-bottom: 2px solid var(--primary-color-100);
}
nav a:hover {
  background-color: var(--bg-300);
}

/*About*/
.about-me-wrapper h1 {
  text-align: center;
}
.about-me-wrapper p {
  white-space: pre-line;
}
.skill-gallery {
  display: flex;
  flex-wrap: wrap;
}
.skill-gallery > * {
  margin-left: 1rem;
  margin-bottom: 1rem;
}
.skill-gallery > div {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.devicon {
  font-size: 4rem;
}
@media (min-width: 1024px) {
  .devicon {
    font-size: 5rem;
  }
}

/*Projects*/
.project-wrapper h1 {
  text-align: center;
}
.project-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  gap: 1.4rem;
}
@media (min-width: 768px) {
  .project-grid {
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  }
}

/*Project card*/
.project-card-wrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--bg-300);
  border-radius: 5px;
  box-shadow: 3px 3px 12px 4px rgba(0, 0, 0, 0.322);
}
.project-card-wrapper h2 {
  text-align: center;
}
.project-card-wrapper h2,
.project-card-wrapper p,
.project-card-wrapper .project-card-links {
  margin: 1rem;
}
.project-card-wrapper img {
  max-width: 100%;
  width: 100%;
  height: 250px;
  object-fit: cover;
}
.project-card-links {
  display: flex;
  justify-self: end;
  margin-top: auto !important;
}
.project-card-links > * {
  flex: 1 1 100%;
}
.project-card-links > * + * {
  margin-left: 1rem;
}

/*Project page*/
.project-page-wrapper h1 {
  text-align: center;
  margin: 1.7rem;
  font-size: 2.5rem;
  line-height: 3rem;
}
.project-page-text-info {
  order: 2;
}
.project-page-text-info * {
  white-space: pre-line;
}
.project-page-wrapper .carousel {
  max-height: 500px !important;
}
@media (min-width: 1024px) {
  .project-page-text-info {
    order: initial;
  }
  .project-page-wrapper .carousel {
    max-height: 600px !important;
  }
}
.project-page-text-info h2 {
  margin: 1.5rem 0;
}
.project-page-text-info h2:first-child {
  margin-top: 0;
}
.contact-btn-buffer {
  border: 2px solid transparent;
}

/*Carousel*/
.carousel-wrapper {
  width: 100%;
  margin-bottom: 1.5rem;
}
.carousel {
  display: flex;
  height: fit-content;
  overflow: auto;
  scroll-snap-type: x mandatory;
  scroll-snap-stop: always;
}
.carousel::-webkit-scrollbar {
  display: none;
}
.carousel > * + * {
  margin-left: 1rem;
}
.carousel img {
  max-width: 100%;
  min-width: 100%;
  object-fit: cover;
  scroll-snap-align: center;
  border-radius: 5px;
}
.carousel-navigation {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.dot-navigation {
  display: flex;
}
.carousel-navigation button {
  color: var(--text-color);
}
@media (min-width: 1024px) {
  .carousel-navigation button {
    font-size: 1.4rem;
  }
}
.dot-active {
  color: var(--primary-color-100) !important;
}
.arrow-disabled {
  opacity: 0;
  pointer-events: none;
}

/*Contact*/
.contact-wrapper h1 {
  text-align: center;
}
.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contact-form > * {
  width: 100%;
  max-width: 400px;
}
.first-last-name-wrapper {
  display: flex;
  flex-direction: column;
}
@media (min-width: 600px) {
  .first-last-name-wrapper {
    flex-direction: row;
  }
  .first-last-name-wrapper > * {
    flex-basis: 100%;
    min-width: 0;
  }
  .first-last-name-wrapper > * + * {
    margin-left: 1rem;
  }
  .contact-form > * {
    width: 100%;
    max-width: 500px;
  }
}
.contact-textarea-wrapper {
  display: flex;
  flex-direction: column;
}
.contact-textarea {
  background-color: var(--bg-400);
  border: none;
  outline: 2px solid rgba(255, 255, 255, 0.295);
  border-radius: 4px;
  color: var(--text-color);
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
  resize: none;
  height: 240px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.send-btn {
  margin-top: 1rem;
  max-width: 200px;
  font-size: 1.5rem;
  padding: 0.5rem 0;
}
.text-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
.text-input span {
  width: fit-content;
}
.text-input input {
  color: var(--text-color);
  background-color: var(--bg-400);
  font-size: 1rem;
  padding: 0.3rem;
  border-radius: 4px;
  outline: 2px solid rgba(255, 255, 255, 0.295);
  border: none;
}
.text-input input:valid:focus,
.contact-textarea-wrapper textarea:valid:focus {
  outline: 2px solid rgba(255, 255, 255, 0.774);
  border: none;
}
.text-input input:invalid:focus,
.contact-textarea-wrapper textarea:invalid:focus {
  outline: 2px solid red !important;
  border: none;
}
.invalid-input-error {
  display: none;
  color: rgb(187, 35, 35);
}
input:invalid:focus ~ .invalid-input-error,
textarea:invalid:focus ~ .invalid-input-error {
  display: block;
}

.form-sent {
  display: flex;
  justify-content: center;
  font-size: 1.3rem;
  white-space: pre-wrap;
  text-align: center;
}

.inactive-warning {
  background-color: rgb(126, 15, 15);
  border-radius: 4px;
  padding: 0.5rem;
  border: 3px solid brown;
}
